const initialState = {
  ListData: {
    success:false,
    statusCode:0,
    message:null,
    data: {
      total_count: 1,
      page_count: 1,
      table: []
    },
    params: {}
  },
  DetailData: {
    success:false,
    statusCode:0,
    message:null,
    data: []
  },
  BlockList: {
    success:false,
    statusCode:0,
    message:null,
    data: []
  },
  ContentBlock: {
    success:false,
    statusCode:0,
    message:null,
    data: []
  },
  SubTitleBlock: {
    success:false,
    statusCode:0,
    message:null,
    data: [
      {
        sub_title_en : '',
        seo_title_tc : '',
        seo_title_sc : '',
        block_title : ''
      }
    ]
  }
}

const contentManagement = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_LISTDATA':
      return {
        ...state,
        ListData: {
          success: action.success,
          statusCode: action.statusCode,
          message: action.message,
          data: action.data
        }
      }
    case 'GET_DETAILDATA':
      return {
        ...state,
        DetailData: {
          success: action.success,
          statusCode: action.statusCode,
          message: action.message,
          data: action.data
        }
      }
    case 'UPDA_DETAILDATA':
      return {
        ...state,
        DetailData:{...state.DetailData, data: [action.data] }
      }
    case 'GET_BlOCKLIST':
      return {
        ...state,
        BlockList: {
          success: action.success,
          statusCode: action.statusCode,
          message: action.message,
          data: action.data
        }
      }
    case 'GET_CONTENTBLOCHK':
      return {
        ...state,
        ContentBlock: {
          success: action.success,
          statusCode: action.statusCode,
          message: action.message,
          data: action.data
        }
      }
    case 'GET_SUBTITLEBLOCK':
      return {
        ...state,
        SubTitleBlock: {
          success: action.success,
          statusCode: action.statusCode,
          message: action.message,
          data: action.data
        }
      }
    case 'UPDA_SUBTITLEBLOCK':
      return {
        SubTitleBlock:{...state.SubTitleBlock, data: [action.data] }
      }
    default:
      return { ...state }
  }
}
export default contentManagement
