const initialState = {
  CheckAvalistData: {
    success: "",
    statusCode: "",
    message: "",
    data: {
      total_count: 1,
      page_count: 1,
      table: []
    },
    params: {}
  },
  CheckAvalistdetailData: {
    success: "",
    statusCode: "",
    message: "",
    data: {
      table: []
    },
    params: {}
  }
}

const Checklist = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_CheckDATA':
      return {
        CheckAvalistData: {
          ...state,
          success: action.CheckAvalistData.success,
          statusCode: action.CheckAvalistData.statusCode,
          message: action.CheckAvalistData.message,
          data: action.CheckAvalistData.data,
          params: action.CheckAvalistData.params
        }
      }
    case 'GET_CheckDetailDATA':
      return {
        ...state,
        CheckAvalistdetailData: {
          success: action.CheckAvalistdetailData.success,
          statusCode: action.CheckAvalistdetailData.statusCode,
          message: action.CheckAvalistdetailData.message,
          data: action.CheckAvalistdetailData.data,
          params: action.CheckAvalistdetailData.params
        }
      }
    default:
      return { ...state }
  }
}
export default Checklist
