const initialState = {
  ListData: {
    success:false,
    statusCode:0,
    message:null,
    data: {
      total_count: 1,
      page_count: 1,
      table: []
    },
    params: {}
  },
  HistoryData: {
    success:false,
    statusCode:0,
    message:null,
    data: {
      total_count: 1,
      page_count: 1,
      table: []
    },
    params: {}
  }
}
  
  const reviewer = (state = initialState, action) => {
    switch (action.type) {
      case 'GET_DATA':
        return {
          ...state,
          ListData: {
            success: action.success,
            statusCode: action.statusCode,
            message: action.message,
            data:action.data,
            params: action.params
          }
        }
      case 'GET_HISTIRY':
        return {
          ...state,
          HistoryData: {
            success: action.success,
            statusCode: action.statusCode,
            message: action.message,
            data:action.data,
            params: action.params
          }
        }
      default:
        return { ...state }
    }
  }
  export default reviewer
  