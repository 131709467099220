const initialState = {
    DetailData: {
      success:"false",
      statusCode:"",
      message:"",
      data: []
    },
    ShowData: {
      data: []
      }
  }
    
    const emailReducer = (state = initialState, action) => {
      switch (action.type) {
        case 'GET_DETAILDATA':
          return {
              ...state,
              DetailData:{
                success:action.success,
                statusCode:action.statusCode,
                message:action.message,
                data: action.data
              }
            }
        case 'UPDA_DETAILDATA':
          return {
            ...state,
            ShowData:{ data: action.data }
          }
        default:
          return { ...state }
      }
    }
    export default emailReducer