const initialState = {
  sharelistData: {
    success: "",
    statusCode: "",
    message: "",
    data: {
      total_count: 1,
      page_count: 1,
      table: []
    },
    params: {}
  },
  sharedetailData: {
    success: "",
    statusCode: "",
    message: "",
    data: {
      table: []
    },
    params: {}
  }
}

const sharelist = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_SharesDATA':
      return {
        sharelistData: {
          ...state,
          success: action.sharelistData.success,
          statusCode: action.sharelistData.statusCode,
          message: action.sharelistData.message,
          data: action.sharelistData.data,
          params: action.sharelistData.params
        }
      }
    case 'GET_SHAREDATA':
      return {
        ...state,
        sharedetailData: {
          success: action.sharedetailData.success,
          statusCode: action.sharedetailData.statusCode,
          message: action.sharedetailData.message,
          data: action.sharedetailData.data,
          params: action.sharedetailData.params
        }
      }
    default:
      return { ...state }
  }
}
export default sharelist
